import React from "react";
import image from "./images/pexels-tranmautritam-326503.jpg";
import star from "./images/star (2).png";

export default function Home() {
  return (
    <main>
      <section className="landing">
        <div className="left">
          <h1>Professional Websites for Local Contractors</h1>
          <p>
            Specializing in crafting custom, beautiful websites that are
            designed to help small businesses grow.
          </p>
        </div>

        <div className="right">
          <img src={image}></img>

          <a href="/contact" className="hoverbtn">
            Get In Touch
          </a>
        </div>
      </section>
      <section className="testimonial">
        <div className="testimonialcontainer">
          <div className="stars">
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
          </div>
          <p>
            "If you need a web page, Pennypack Web Designs is where to go.
            Sensational service and very respectful to their clients. A+"
          </p>
          <p className="testimonialauthor">-ATC Lawn Care</p>
        </div>
      </section>
    </main>
  );
}
