import React from "react";
import image from "./images/IMG_0776.jpg";

export default function About() {
  return (
    <main className="about">
      <div id="content">
        <div className="left">
          <h1 className="underline">About</h1>
          <p>
            Hello! I’m Dominick, the owner and sole developer of Pennypack Web
            Designs. My journey into programming began a few years ago with a
            passion for game development, which eventually sparked a deep
            interest in web development. I became fascinated by how websites
            function behind the scenes, and this curiosity drove me to start
            creating websites. Over time, I turned this passion into a
            professional pursuit, founding my own web development company.
          </p>
        </div>

        <div className="right">
          <img src={image} id="image"></img>
          <a href="/services" className="hoverbtn">
            What We offer
          </a>
        </div>
      </div>
    </main>
  );
}
